import { FORWARD_SLASH } from "@lib/helper/stringUtils";

export const PATH_ROOT = FORWARD_SLASH;

export enum PathType {
  AllBrands,
  Brands,
  Products,
  Root,
}

export const PATHS: Map<PathType, string> = new Map([
  [PathType.AllBrands, "/all-brands"],
  [PathType.Brands, "/brands"],
  [PathType.Products, "/products"],
  [PathType.Root, PATH_ROOT],
]);

export const LEGAL_SLUGS = ["legal-notices", "terms-of-use"];

export function getRootPath(locale?: string): string {
  return getPath(PathType.Root, locale);
}

export function getPath(type: PathType, locale?: string): string {
  const path = PATHS.get(type);
  const finalPath = path ? path : PATH_ROOT;
  return `${locale ? `/${locale}` : ""}${finalPath}`;
}

export function getBrandPath(code?: string, locale?: string): string {
  const brandsPath = getPath(PathType.Brands, locale);
  if (!code) return brandsPath;
  return `${brandsPath}${FORWARD_SLASH}${code}`;
}

export function getAllBrandPath(index?: string): string {
  const allBrandsPath = getPath(PathType.AllBrands);
  if (!index) return allBrandsPath;
  return `${allBrandsPath}${FORWARD_SLASH}${index}`;
}

export const PATHS_HIERARCHY: Map<PathType, number> = new Map([
  [PathType.Root, 0],
  [PathType.AllBrands, 1],
  [PathType.Brands, 2],
  [PathType.Products, 2],
]);

export const getPrevInHierarchy = (path: string): string => {
  // By default, redirect to the home page.
  const defaultPrevInHierarchy = getPath(PathType.Root);

  const type: PathType[] = [...PATHS.entries()].filter((entry) => path.startsWith(entry[1])).map((entry) => entry[0]);
  if (type.length === 0) return defaultPrevInHierarchy;

  const hierarchy: number | undefined = PATHS_HIERARCHY.get(type[0]);
  if (!hierarchy || hierarchy === 0) return defaultPrevInHierarchy;

  const prevInHierarchy: PathType[] = [...PATHS_HIERARCHY.entries()]
    .filter((entry) => entry[1] === hierarchy - 1)
    .map((entry) => entry[0]);
  return prevInHierarchy.length === 0 ? defaultPrevInHierarchy : getPath(prevInHierarchy[0]);
};

export const getCanonicalLink = (locale?: string, path = ""): string | undefined => {
  return process.env.NEXT_PUBLIC_WEBSITE_URL
    ? `${process.env.NEXT_PUBLIC_WEBSITE_URL}${locale === "en" ? "" : "/fr"}${path}`
    : undefined;
};

export enum QueryParameter {
  BRAND = "brand",
  COLLECTION = "collection",
  PRODUCT_KEY = "productKey",
  LOCALE = "locale",
}

export const getQueryParameter = (
  parameter: QueryParameter,
  query = new URLSearchParams(location.search),
): string | null => {
  return query.get(parameter);
};

export const verifyRequiredParams = (params: { [key: string]: string }, requiredParams: string[] = []): boolean => {
  return requiredParams.every((param) => Object.keys(params).includes(param));
};

export const computePathWithParams = (rawPath: string, params: { [key: string]: string }) => {
  let result = rawPath;

  for (const [key, value] of Object.entries(params)) {
    if (key === QueryParameter.LOCALE) continue;

    result = result.replaceAll(`{{${key}}}`, value);
  }
  if (Object.keys(params).includes(QueryParameter.LOCALE)) {
    return `/${params[QueryParameter.LOCALE]}/${result}`;
  }
  return result;
};
